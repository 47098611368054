import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { ToastContainer } from 'react-toastify';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from "./routes";
import 'react-toastify/dist/ReactToastify.css';



function App() {
  return (
    <Router>
      <AppRoutes />
      <ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
    </Router>
    
  );
}

export default App;
