const LANGUAGE = {
    CHINESE:1,
    ENGLISH:2
}





export {
    LANGUAGE
};