import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { useDropzone } from 'react-dropzone';
import 'react-phone-number-input/style.css';
import styles from '../styles/style.module.css';
import calndr from '../../../assets/images/calndr.png';
import simscan from '../../../assets/images/simscan.png';
import vsscaner from '../../../assets/images/vsscaner.png';
import wechat from '../../../assets/images/wechat.png';
import upic from "../../../assets/images/upic.png";
import { BasicDetailSchema } from '../validation';
import { addBasicDetails, getEmployeeDetails, uploadImage } from '../api';
import MainLoader from '../../mainLoader';
import Resizer from 'react-image-file-resizer';
import BarCodeScannerModal from '../../modal/barcodeScannerModal';
import { LANGUAGE } from '../../../utils/constants';
import PlanDetails from './PlanDetails';
import { sendOtp, verifyOtp } from '../../basicdetail/api';
import { validateEmail } from '../../../lib/emailValidation';
import ThankYouModal from '../../modal/thankyou';
import 'react-phone-number-input/style.css';


const ChinesFormDetails: React.FC = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [previewImage, setImagePreview] = useState("");
  const [imageError, setImageError] = useState("");
  const [selectLan, setSelectLan] = useState(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalScanner, setShowModalScanner] = useState<boolean>(false);
  const [simNos, setSimNos] = useState<string>('');
  const [getPlanDetails, setGetPlanDetails] = useState<boolean>(true);
  const [dataPack, setDataPack] = useState<number>(0);
  const location = useLocation();
  const queryParams: any = new URLSearchParams(location.search);
  const employeeId = queryParams.get("employeeId");
  const [employeeDetails, setEmployeeDetails] = useState<any>()
  const [otpId, setOTPID] = useState('');
  const [show, setShow] = useState("")
  const [timer, setTimer] = useState(0);
  const [isEmailVerified, setIsEmailVerified] = useState(false)
  const resendOtpHandle = useRef<HTMLAnchorElement>(null);


  const ClosedModal = (newValue: any) => {
    setShow(newValue);
  };

  let fname, lname, email, phoneNo, travellDate, returnDate, simNo, paymentBill, empName, empId, dragDrop, dragAndDrop, formSubmit, fnamep, lnamep, emailp, phoneNop, travellDatep, simNop, title;
  if (selectLan == LANGUAGE.CHINESE) {
    title = "输入您的详细信息";
    fname = "名";
    fnamep = "输入您的名字";
    lname = "姓";
    lnamep = "输入您的姓氏";
    email = "电子邮件";
    emailp = "输入你的电子邮箱";
    phoneNo = "电话";
    phoneNop = "输入你的电话号码";
    travellDate = "出行日期";
    simNo = "卡号";
    simNop = "扫描您的 SIM 卡号码";
    paymentBill = "上传付款证明";
    empName = "员工姓名";
    empId = "员工代码";
    dragDrop = "将文件拖放到此处..."
    dragAndDrop = "拖放或选择 PDF/Word 文件上传";
    formSubmit = "提交"

  } else if (selectLan == LANGUAGE.ENGLISH) {
    title = "Enter your details";
    fname = "First Name";
    fnamep = "Enter your First name";
    lname = "Last Name";
    lnamep = "Enter your last name";
    email = "Email";
    emailp = "Enter your email";
    phoneNo = "Phone";
    phoneNop = "Enter your phone";
    travellDate = "Traveling date";
    simNo = "Sim Number";
    simNop = "Scan you sim number";
    paymentBill = "Upload Payment Proof";
    empName = "Employee Name";
    empId = "Employee Code";
    dragDrop = "Drop the files here..."
    dragAndDrop = "Drag & Drop or choose PDF/Word File to upload";
    formSubmit = "Submit"
  }


  const validTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      countryCode: "",
      phoneNumber: "",
      travellingDate: "",
      paymentProof: "",
      // simNumber: simNos || "",
    },
    validationSchema: BasicDetailSchema(selectLan),
    onSubmit: async (values, { setFieldError }) => {

      setLoader(true);
      //   if(!isEmailVerified) {
      //     setFieldError('email', 'email is not verified!');
      //     setLoader(false)
      //     return
      //  }

      const parsedNumber = parsePhoneNumber(values.phoneNumber);
      let BasicPayload: any = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        countryCode: `+${parsedNumber?.countryCallingCode as string}`,
        phoneNumber: parsedNumber?.nationalNumber as string,
        travellingDate: values.travellingDate,
        // simNumber: values.simNumber,
        paymentProof: previewImage,
        employeeId: employeeDetails?._id,
        dataPack: dataPack
      };
      // if(previewImage) BasicPayload.paymentProof = previewImage;

      toast.promise(
        addBasicDetails(BasicPayload),
        {
          pending: 'Trying to save your informations...',
          success: {
            render({ data }: any) {
              setLoader(false);
              setShow("show")
              return "Your informations successfully saved";
            }
          },
          error: {
            render({ data }: any) {
              setLoader(false);
              return data.data.message;
            }
          }
        });
    },
  });
  useEffect(() => {
    formik.setFieldValue('simNumber', simNos);
    getEmployeeDetails(employeeId).then((data) => {
      setEmployeeDetails(data?.data)
      setLoader(false)
    }).catch((error) => {
      console.log(error)
      toast.error(error?.data?.message || "Something went worng!");
    })
  }, [simNos])

  const onDrop = (acceptedFiles: File[]) => {

    if (!validTypes.includes(acceptedFiles[0].type)) {
      if (selectLan == LANGUAGE.CHINESE) setImageError("请上传 PNG、JPG、JPEG、PDF 或 WORD 文件。");
      if (selectLan == LANGUAGE.ENGLISH) setImageError("Please upload a PNG, JPG, JPEG, PDF or WORD file.");

      return false;
    }
    setImageError("")
    setLoader(true)

    if (['image/png', 'image/jpg', 'image/jpeg'].includes(acceptedFiles[0].type)) {
      Resizer.imageFileResizer(
        acceptedFiles[0],
        300, // max width
        300, // max height
        'JPEG', // format
        100, // quality
        0, // rotation
        (uri: any) => {
          const resizedFile = new File([uri], acceptedFiles[0].name, { type: acceptedFiles[0].type });
          const formData = new FormData();
          formData.append('file', resizedFile);
          uploadImage(formData).then((imageData) => {
            formik.setFieldValue('paymentProof', imageData.data.url)
            setImagePreview(imageData.data.url);
            setLoader(false);
          }).catch((error) => {
            setImageError('Something went wrong!');
            setLoader(false);
            return false;
          })
        },
        'blob' // output type
      );

    } else {
      const formData = new FormData();
      formData.append('file', acceptedFiles[0]);
      uploadImage(formData).then((imageData) => {
        formik.setFieldValue('paymentProof', imageData.data.url)
        setImagePreview(imageData.data.url);
        setLoader(false);
      }).catch((error) => {
        setImageError('Something went wrong!');
        setLoader(false);
        return false;
      })
    }

  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const today = new Date().toISOString().split('T')[0];

  if (getPlanDetails) return (
    <>
      <PlanDetails setGetPlanDetails={setGetPlanDetails} setDataPack={setDataPack} setSelectLan={setSelectLan} selectLan={selectLan} />
    </>

  )

  return (
    <div className={`${styles.bassoDtailFoem} ${styles.baseBnner}`}>
      {loader && <MainLoader />}
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.ChangeLanguage}>
          <h4>{title}</h4>
          <div className="switch">
            <input id="language-toggle" onChange={(e) => {
              const value = e.target.checked;
              if (!value) setSelectLan(1)
              if (value) setSelectLan(2)

            }} className="check-toggle check-toggle-round-flat" type="checkbox" checked={selectLan === 2} />
            <label htmlFor="language-toggle"></label>
            <span className="on">CH</span>
            <span className="off">EN</span>

          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className={styles.formGroup}>
              <label>{fname}*</label>
              <input
                type="text"
                name="firstName"
                placeholder={fnamep}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className={styles.error}>{formik.errors.firstName}</div>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className={styles.formGroup}>
              <label>{lname}*</label>
              <input
                type="text"
                name="lastName"
                placeholder={lnamep}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className={styles.error}>{formik.errors.lastName}</div>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className={styles.formGroup}>
              <label>{email}*
              </label>
              <input
                type="text"
                name="email"
                placeholder={emailp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email && (
                <div className={styles.error}>{formik.errors.email}</div>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className={styles.formGroup}>
              <label>{phoneNo}*</label>
              <PhoneInput
                international
                defaultCountry="CN"
                className={styles.phoneNumber}
                placeholder={phoneNop}
                onChange={(value) => formik.setFieldValue('phoneNumber', value)}
                value={formik.values.phoneNumber}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                <div className={styles.error}>{formik.errors.phoneNumber}</div>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className={styles.formGroup}>
              <label>{travellDate}*</label>
              {/* <input
                type="date"
                name="travellingDate"
                placeholder="Select Date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.travellingDate}
                min={today}
              /> */}
              <DatePicker
                selected={formik.values.travellingDate ? new Date(formik.values.travellingDate) : null} // Ensure it's a Date object
                onChange={(date) => formik.setFieldValue('travellingDate', date)} // Set the date
                name="travellingDate"
                placeholderText="Select Travelling Date"
                onBlur={formik.handleBlur}
                dateFormat="dd-M-yyyy" // Optional
                value={formik.values.travellingDate} 
                minDate={new Date()} />
              <span><img src={calndr} alt="Calendar Icon" /></span>
              {formik.touched.travellingDate && formik.errors.travellingDate && (
                <div className={styles.error}>{formik.errors.travellingDate}</div>
              )}
            </div>
          </div>
          {/* <div className="col-md-12">
            <div className={styles.formGroup}>
              <label>{simNo}*</label>
              <div className={styles.simNumber}>
                <input
                  type="text"
                  name="simNumber"
                  placeholder="8944xxxxxxxxxxxxxxx"
                  onChange={(e) => {
                    let val: string = (e.target.value); 
                    if (val.length === 0 || val.length === 4) { 
                      val = ""; 
                    } else if (val.length === 1) {
                      val = "8944" + val; 
                    } else if (val.length > 1) {
                      val = "8944" + val?.slice(4);
                    }
                    setSimNos(val);
                  }}
                  
                  onBlur={formik.handleBlur}
                  value={simNos}
                />
              </div>
              {formik.touched.simNumber && formik.errors.simNumber && (
                <div className={styles.error}>{formik.errors.simNumber}</div>
              )}
            </div>
          </div> */}

          <div className={styles.paynowScan}>
            <h4>Pay Now</h4>
            <div className={styles.scanCode}>
              <img src={vsscaner} alt="" />
            </div>
            <div className={styles.wechat}>
              <img src={wechat} alt="" />
            </div>
          </div>
          <div className="col-md-12">
            <div className={styles.formGroup}>
              <label>{paymentBill}</label>
              <div {...getRootProps({ className: styles.UplaodFile })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <span>
                    <img src={upic} alt="" />
                    <p>{dragDrop}</p>
                  </span>
                ) : (
                  <span>
                    <img src={upic} alt="" />
                    <p>{dragAndDrop}</p>
                  </span>
                )}

              </div>
              {
                imageError && <div className={styles.error}>{imageError}</div>
              }
              {formik.touched.paymentProof && formik.errors.paymentProof && (
                <div className={styles.error}>{formik.errors.paymentProof}</div>
              )}
            </div>
          </div>
          <div className="col-md-12">
            <div className={styles.formGroup}>
              <input type="submit" value={formSubmit} />
            </div>
          </div>
        </div>
      </form>
      <ThankYouModal showModal={show} closeModal={ClosedModal} />
    </div>
  );
};

export default ChinesFormDetails;
