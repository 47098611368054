import React from 'react'
import clap from "../../assets/images/clap.gif"
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../lib/useAuth';
import data from "../../assets/images/data.svg"
import speed from "../../assets/images/speed.svg"
import validity from "../../assets/images/validity.svg"
interface Props {
  show: boolean;
  setShowModal: (show: boolean) => void;
}
const Congratulations: React.FC<Props> = ({ show, setShowModal }) => {
  const navigate = useNavigate();
  const { logout } = useAuth()
  return (
    <div className='Congratmodal'>
      <div className="modal" style={show ? { display: "block" } : { display: "none" }}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                setShowModal(false)
              }}><i className="fas fa-times"></i></button>
            </div>
            <div className="modal-body">
              <div className='conText'>
                <img src={clap} alt="" />
                <h4>Congratulation!!</h4>
                <h5>Thanks a bunch!!</h5>
                <p>We're thrilled to have you as a valued customer and<br /> can't wait to serve you again! 🎉<br />
                  <b> Keep an eye on your inbox – a confirmation email is<br /> on its way! 📧✨</b></p>
                {/* <Link to='/'>Back to Home</Link> */}
              </div>
              
              {/* <div className='popular-plans'>
                <h2>Popular Plans</h2>
                <div className='row'>
                  <div className="col-md-4">
                    <div className="esimDetails">
                      <div className="FirtPack">
                        <div className="PackHead">
                          <div className="PackHeadLeft">
                            <h3>{bundle?.name} <br /> <span>Country</span></h3>
                            <h4>UKi Mobile</h4>
                            <p>Company</p>
                          </div>
                          <div className="PackHeadRight">
                            <span>
                              $ 4.56
                            </span>
                          </div>
                        </div>
                        <h3>{bundle?.name}</h3>
                        <ul>
                          <li>
                            <span>
                              <img src={data} alt="" /> Data
                            </span>
                            <h5>
                              1 GB
                            </h5>
                          </li>
                          <li>
                            <span>
                              <img src={validity} alt="" /> Validity
                            </span>
                            <h5>7 Days</h5>
                          </li>
                          <li>
                            <span>
                              <img src={speed} alt="" /> Speed
                            </span>
                            <h5>
                              5 G
                            </h5>
                          </li>
                        </ul>
                        <div className="viewAll">
                          <button
                            type="button"
                            className="buySimBtn"

                          >
                            BUY NOW
                          </button>

                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="esimDetails">
                      <div className="FirtPack">
                        <div className="PackHead">
                          <div className="PackHeadLeft">
                            <h3>{bundle?.name} <br /> <span>Country</span></h3>
                            <h4>UKi Mobile</h4>
                            <p>Company</p>
                          </div>
                          <div className="PackHeadRight">
                            <span>
                              $ 4.56
                            </span>
                          </div>
                        </div>
                        <h3>{bundle?.name}</h3>
                        <ul>
                          <li>
                            <span>
                              <img src={data} alt="" /> Data
                            </span>
                            <h5>
                              1 GB
                            </h5>
                          </li>
                          <li>
                            <span>
                              <img src={validity} alt="" /> Validity
                            </span>
                            <h5>7 Days</h5>
                          </li>
                          <li>
                            <span>
                              <img src={speed} alt="" /> Speed
                            </span>
                            <h5>
                              5 G
                            </h5>
                          </li>
                        </ul>
                        <div className="viewAll">
                          <button
                            type="button"
                            className="buySimBtn"

                          >
                            BUY NOW
                          </button>

                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="esimDetails">
                      <div className="FirtPack">
                        <div className="PackHead">
                          <div className="PackHeadLeft">
                            <h3>{bundle?.name} <br /> <span>Country</span></h3>
                            <h4>UKi Mobile</h4>
                            <p>Company</p>
                          </div>
                          <div className="PackHeadRight">
                            <span>
                              $ 4.56
                            </span>
                          </div>
                        </div>
                        <h3>{bundle?.name}</h3>
                        <ul>
                          <li>
                            <span>
                              <img src={data} alt="" /> Data
                            </span>
                            <h5>
                              1 GB
                            </h5>
                          </li>
                          <li>
                            <span>
                              <img src={validity} alt="" /> Validity
                            </span>
                            <h5>7 Days</h5>
                          </li>
                          <li>
                            <span>
                              <img src={speed} alt="" /> Speed
                            </span>
                            <h5>
                              5 G
                            </h5>
                          </li>
                        </ul>
                        <div className="viewAll">
                          <button
                            type="button"
                            className="buySimBtn"

                          >
                            BUY NOW
                          </button>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Congratulations
