import { API_URL } from '../../../config';
import { axios } from '../../../lib/axios';



  const addBasicDetails = async (values: any): Promise<any> => {

    const headers = {
      'X-Origin-Domain' : "ukvl.commbitz.com"
    };
  
    // Pass the headers as the third parameter
    return axios.post(`${API_URL}/vfs/physicalEsim`, values, { headers });
  };

const getEmployeeDetails = async (id:string): Promise<any> => {
    return await axios.get(`/vfs/employee?employeeId=${id}`);
}

const uploadImage = async (values:any) => {
  const headers:any = {
      'Content-Type': 'multipart/form-data',
    };
  return axios.post(`${API_URL}/common/upload`, values, headers);
}

export {
    addBasicDetails,
    uploadImage,
    getEmployeeDetails
};