import mainLoader  from "../../assets/images/loader.gif"
import  Styles from "./style.module.css"

const MainLoader = () => {
  return (
    <div className={Styles.mainLoader}> 
        <div className="innerLoader">
            <img src={mainLoader} alt="filter" />
        </div>        
    </div>
  )
}

export default MainLoader