import React, { useState } from "react";
import styles from "./style.module.css"
import sucess from "../../assets/images/sucess.png"


interface EmailModalProp {
  showModal: string;
  closeModal: any
}

const ThankYouModal: React.FC<EmailModalProp> = ({ showModal, closeModal }) => {

  return (
    <div className={`modal fade ${showModal ? showModal : ''}`}
      style={showModal ? { display: "block" } : { display: "none" }}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" onClick={() => closeModal("")} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className={styles.EsimModal}>
            <img src={sucess} alt="" />
              <p>Thanks a bunch!! <br/><br/></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThankYouModal