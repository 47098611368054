import { isPossiblePhoneNumber, isValidPhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';

const messages : any = {
    2: {
      firstName: {
        required: 'Please enter first name',
        matches: 'Name contains only alphabetic characters',
        max: 'Name should not exceed 25 characters',
        noWhitespace: 'Full name should not start or end with whitespace'
      },
      lastName: {
        required: 'Please enter last name',
        matches: 'Name contains only alphabetic characters',
        max: 'Name should not exceed 25 characters',
        noWhitespace: 'Full name should not start or end with whitespace'
      },
      simNumber: {
        required: 'Sim number is required field!',
        matches: 'Sim number contains only numbers',
        noWhitespace: 'Sim number should not start or end with whitespace'
      },
      phoneNumber: {
        required: 'Phone number is required field!',
        invalid: 'Invalid mobile number'
      },
      email: {
        required: 'Please enter email',
        email: 'Invalid email format'
      },
      travellingDate: {
        required: 'Travelling date is required',
        typeError: 'Invalid date format',
        min: 'Travelling date must be today or later'
      },
      returnDate: {
        typeError: 'Invalid date format',
        min: 'Return date must be later than or equal to travelling date'
      },
      paymentProof: {
        required: 'Payment Proof is required field!'
      }
    },
    1 : {
      firstName: {
        required: '请输入名字',
        matches: '名字只能包含字母字符',
        max: '名字不得超过25个字符',
        noWhitespace: '全名不应以空格开头或结尾'
      },
      lastName: {
        required: '请输入姓氏',
        matches: '姓氏只能包含字母字符',
        max: '姓氏不得超过25个字符',
        noWhitespace: '全名不应以空格开头或结尾'
      },
      simNumber: {
        required: 'SIM卡号是必填字段！',
        matches: 'SIM卡号只能包含数字',
        noWhitespace: 'SIM卡号不应以空格开头或结尾'
      },
      phoneNumber: {
        required: '手机号码是必填字段！',
        invalid: '无效的手机号码'
      },
      email: {
        required: '请输入电子邮件',
        email: '无效的电子邮件格式'
      },
      travellingDate: {
        required: '旅行日期是必填项',
        typeError: '无效的日期格式',
        min: '旅行日期必须是今天或之后'
      },
      returnDate: {
        typeError: '无效的日期格式',
        min: '返回日期必须晚于或等于旅行日期'
      },
      paymentProof: {
        required: '支付凭证是必填字段！'
      }
    }
  };

// Helper function to parse dates correctly
const parseDateString = (value: any, originalValue: any): Date => {
    const parsedDate = Date.parse(originalValue);
    return isNaN(parsedDate) ? new Date() : new Date(parsedDate);
};

// Get the current date and set the time to 00:00:00 to ensure only the date part is compared
const today = new Date();
today.setHours(0, 0, 0, 0);

const BasicDetailSchema = (language:number) => {
    const msg:any = messages[language];
 return Yup.object().shape({
  firstName: Yup.string()
        .required(msg?.firstName?.required)
        .matches(/^[A-Za-z\u4e00-\u9fa5\s]+$/, `${msg?.firstName?.matches}`)
        .max(25)
        .test('no-whitespace', `${msg?.firstName?.noWhitespace}`, value => {
            if (value) {
                return value === value.trim();
            }
            return true;
        }),
    lastName: Yup.string()
        .required(msg?.lastName?.required)
        .matches(/^[A-Za-z\u4e00-\u9fa5\s]+$/, `${msg?.lastName?.matches}`)
        .max(25)
        .test('no-whitespace', `${msg?.lastName?.noWhitespace}`, value => {
            if (value) {
                return value === value.trim();
            }
            return true;
        }),
        // simNumber: Yup.string()
        // .required(msg?.simNumber?.required)
        // .matches(/^[0-9\s]+$/, msg?.simNumber?.matches)
        // .min(19, "Please enter 19 digit sim number")
        // .max(19, "Please enter 19 digit sim number") 
        // .test('no-whitespace', msg?.simNumber?.noWhitespace, (value) => {
        //   if (value) {
        //     return value === value.trim();
        //   }
        //   return true;
        // }),
    phoneNumber: Yup.string()
        .required(msg?.phoneNumber?.required),
        // .test('phone-number', function (value) {
        //     if (value) {
        //         if (!isPossiblePhoneNumber(value) || !isValidPhoneNumber(value)) {
        //             return this.createError({ message: `${msg?.phoneNumber?.invalid}`, path: this.path });
        //         }
        //         return true;
        //     }
        //     return true;
        // }),
    email: Yup.string()
        .required(msg?.email?.required)
        .email((msg?.email?.email)),
    travellingDate: Yup.date()
        .required(msg?.travellingDate?.required)
        .typeError(msg?.travellingDate?.typeError)
        .transform(parseDateString)
        .min(today, `${msg?.travellingDate?.min}`),
    returnDate: Yup.date()
        .notRequired()
        .typeError(msg?.returnDate?.typeError)
        .transform(parseDateString)
        .when('travellingDate', (travellingDate, schema) => {
            return travellingDate ? schema.min(travellingDate, `${msg?.returnDate?.min}`) : schema;
        }),
        paymentProof:Yup.string().notRequired()
});
}

export {
    BasicDetailSchema
};
