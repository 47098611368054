import { API_URL } from '../../../config';
import { axios } from '../../../lib/axios';

const addBasicDetails = async (values: any): Promise<any> => {

    const headers = {
      'X-Origin-Domain' : "ukvl.commbitz.com"
    };

    // Pass the headers as the third parameter
    return axios.post(`${API_URL}/auth/partnerLogin`, values, { headers });
  };

const getCountries = async (): Promise<any> => {
    return await axios.get(`${API_URL}/regions/countries?limit=300`);
}

const sendOtp = async (values: any): Promise<any> => {
  return axios.post(`${API_URL}/otp/send`, values);
}

const verifyOtp = async (values: any): Promise<any> => {
  return axios.post(`${API_URL}/otp/verify`, values);
}
const addDocuments = async (values:any) : Promise<any> => {
  return axios.post(`${API_URL}/order/freeEsim`, values);
}

export {
    addBasicDetails,
    getCountries,
    sendOtp,
    verifyOtp,
    addDocuments
};